import React, { Suspense, lazy } from 'react';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const AboutMeConfigModal = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../AboutMeConfigModal')
);

const AboutMeConfigModalV3 = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../AboutMeConfigModal.editor_gruser_about')
);

type Props = any;

const AboutMeConfigModalLazy: React.FC<Props> = props => {
  const editor_gruser_about = useFlag('editor_gruser_about');
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      {editor_gruser_about ? (
        <AboutMeConfigModalV3 {...props} />
      ) : (
        <AboutMeConfigModal {...props} />
      )}
    </Suspense>
  );
};
AboutMeConfigModalLazy.displayName = 'AboutMeConfigModalLazy';

export default AboutMeConfigModalLazy;
