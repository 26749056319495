import React, { useCallback, useEffect, useRef, Suspense, lazy } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { BiData, DonationPoolSetupConfirmBiEvent } from '@wix/da-bi/pkg/events';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import PointsIcon from '@wix/da-ds/pkg/Icons/Points';
import InputLabelText from '@wix/da-ds/pkg/formControls/lego/InputLabelText';
import ModalFormDialog from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
import { EditorContextProviderWrapper } from '@wix/da-editor-tiptap/pkg/components/Editor/Context';
import { EditorApi } from '@wix/da-editor-tiptap/pkg/components/Editor/types';
import yupResolver from '@wix/da-shared-react/pkg/utils/yupResolver';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import { useModuleConfig } from '@wix/da-gruser-shared/pkg/hooks/useModuleConfig';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import retry from '@wix/da-shared-react/pkg/utils/webpack/chunkRetry';
import DonationEditor from './DonationEditor';
import { TITLE_MAX_LENGTH, GOAL_MIN_VALUE, GOAL_MAX_VALUE } from './constants';

import s from './DonationsConfigModal.scss';

export enum FieldNames {
  Title = 'title',
  Goal = 'goal',
  Description = ' description',
}

export interface Props {
  moduleId: number;
  onClose(): void;
}

const EditorToolbar = lazy(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "editor_v3", webpackPrefetch: true */
        '@wix/da-editor-tiptap/pkg/components/menus/PluginInsertMenu'
      )
  )
);

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('widgets.donations.form.title.required')
    .max(TITLE_MAX_LENGTH, 'widgets.donations.form.title.tooLong'),
  goal: Yup.string()
    .test('noLetters', 'widgets.donations.form.goal.numeric', value =>
      /^\d+$/.test(value)
    )
    .test(
      'minValue',
      'widgets.donations.form.goal.minExceeded',
      value => parseInt(value) >= GOAL_MIN_VALUE
    )
    .test(
      'maxValue',
      'widgets.donations.form.goal.maxExceeded',
      value => parseInt(value) <= GOAL_MAX_VALUE
    )
    .required('widgets.donations.form.goal.required'),
});

const DonationsConfigModal: React.FC<Props> = ({ moduleId, onClose }) => {
  const { t } = useTranslation();
  const logBiEvent = useBiLogger();
  const { moduleConfig, fetchModuleConfig, saveModuleConfig } = useModuleConfig(
    moduleId,
    'donations'
  );
  const config = moduleConfig?.config;
  const text = config?.description;
  const formContext = useForm({ resolver: yupResolver(validationSchema) });
  const {
    errors,
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = formContext;

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollInvalidIntoView = useCallback(() => {
    const fieldNames = Object.keys(errors);
    for (let i = 0; i < fieldNames.length; i++) {
      const field = errors?.[fieldNames[i]]?.ref?.current;
      if (field) {
        field.focus();
        return;
      }
    }
  }, [errors]);

  const editorApiRef = useRef<EditorApi | null>();
  const saveConfig = useCallback(
    ({ title, goal }) => {
      saveModuleConfig &&
        saveModuleConfig({
          title,
          goal,
          editorRaw: JSON.stringify(editorApiRef.current?.getStateForSave()),
          editor_v3: 'yes',
        });

      // Send BI event when user confirms the edit changes
      logBiEvent &&
        logBiEvent(
          BiData<DonationPoolSetupConfirmBiEvent>({
            evid: 296,
            donation_goal_points: goal,
          })
        );
      onClose();
    },
    [onClose, logBiEvent, saveModuleConfig]
  );

  useEffect(() => {
    scrollInvalidIntoView();
  }, [scrollInvalidIntoView]);

  useEffectOnceOnMount(() => {
    fetchModuleConfig();
  });

  useEffect(() => {
    if (!config) {
      return;
    }
    reset({
      [FieldNames.Title]: config.title,
      [FieldNames.Goal]: config.goal,
    });
  }, [config, reset]);

  const translateError = useCallback(
    error => error?.message && t(error?.message),
    [t]
  );

  if (!config) {
    return null;
  }

  return (
    <EditorContextProviderWrapper>
      <FormProvider {...formContext}>
        <ModalFormDialog
          isOpen
          innerScrollRef={node => {
            scrollRef.current = node;
          }}
          onSubmit={handleSubmit(saveConfig)}
          showHeaderBorder
          showFooterBorder
          disabledSubmit={isSubmitting}
          onCancel={onClose}
          onClose={onClose}
          title={t('widgets.donations.edit.header')}
          footerExtras={
            <Suspense fallback={null}>
              <EditorToolbar className={s['editor-toolbar']} />
            </Suspense>
          }
        >
          <div className={s['content-wrapper']}>
            <div className={s['form']}>
              <div className={s['form-item']}>
                <Controller
                  control={control}
                  name={FieldNames.Title}
                  render={({ onChange, value, name, ref }) => (
                    <TextInput
                      id="donation-title-input"
                      validationResult={errors?.[name] ? 'error' : undefined}
                      details={translateError(errors?.[name])}
                      label={t('widgets.donations.form.title')}
                      maxLength={TITLE_MAX_LENGTH}
                      className={s['input-text']}
                      autoFocus={true}
                      onChange={ev => onChange(ev.target.value)}
                      value={value}
                      ref={node => (ref.current = node) as any}
                      autoComplete="off"
                    />
                  )}
                />
              </div>
              <div className={s['form-item']}>
                <Controller
                  control={control}
                  name={FieldNames.Goal}
                  render={({ onChange, value, name, ref }) => (
                    <TextInput
                      id="donation-goal-input"
                      validationResult={errors?.[name] ? 'error' : undefined}
                      details={translateError(errors?.[name])}
                      label={t!('widgets.donations.goal')}
                      min={GOAL_MIN_VALUE}
                      max={GOAL_MAX_VALUE}
                      className={s['input-number']}
                      onChange={ev => onChange(ev.target.value)}
                      value={value}
                      ref={node => (ref.current = node) as any}
                      autoComplete="off"
                      postfix={<PointsIcon size={IconSize.SMALL} />}
                    />
                  )}
                />
              </div>

              <div className={s['form-item']}>
                <InputLabelText className={s['description-label']}>
                  {t!('widgets.donations.form.description')}
                </InputLabelText>
                <DonationEditor
                  initialContent={
                    text?.html?.type === 'writer' ? undefined : text
                  }
                  scrollRef={scrollRef}
                  onEditorReady={api => {
                    editorApiRef.current = api;
                  }}
                />
              </div>
            </div>
          </div>
        </ModalFormDialog>
      </FormProvider>
    </EditorContextProviderWrapper>
  );
};

export default DonationsConfigModal;
