// DS: TA0.2M
import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import { ProfileOwnerSubscribeButton } from '@wix/da-shared-react/pkg/Button';
import { getIsGroupProfile } from '../../../selectors/users';
import { useSelector } from 'react-redux';
import AvatarBirthdayBadge from '../ProfileIdentity/AvatarBirthdayBadge';
import JoinGroupButton from '@wix/da-shared-react/pkg/JoinGroupButton';
import MoreActionsDropdownMobile from '../ProfileIdentity/MoreActionsDropdown/Mobile';
import MonetizationPromoBanner from '../MonetizationPromoBanner';
import WatchButton from './WatchButton';
import NotesButton from './NotesButton';
import SkinButton from './SkinButton';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import ProfileStatItem from '../ProfileIdentity/ProfileStatItem';
import AvatarEdit from '../ProfileIdentity/AvatarEdit';
import { useHeroImageForContainer } from '@wix/da-shared-react/pkg/utils/hooks/useHeroImageForContainer';
import { setIsEditorOpen } from '../../../actions/avatar';
import SubmitButton from '../../SubNavBar/Desktop/variants/GroupProfileSubNavDesktopInner/_partials/SubmitButton';

import s from './ProfileHeroMobile.scss';

export interface Props {
  className?: string;
  coverDeviation?: PapiDeviation;
  profileOwnerUser: PapiUser;
  canChangeAvatar: boolean;
  isAuthorizedToCustomize: boolean;
  isOwner: boolean;
  hasSubscriptions: boolean;
  isGroup: boolean;
  stats: object;
  tagline?: string;
  openAvatarEditor: typeof setIsEditorOpen;
}

export const ProfileHeroMobile: React.FC<Props> = ({
  className,
  stats,
  tagline = '',
  coverDeviation,
  profileOwnerUser,
  isAuthorizedToCustomize,
  canChangeAvatar,
  isOwner,
  isGroup,
  hasSubscriptions,
  openAvatarEditor,
}) => {
  const isGroupProfilesEnabled = useFlag('group_profiles');
  const isGroupProfile = useSelector(getIsGroupProfile);
  const showSubscribeButton = hasSubscriptions && !isOwner;

  const showIconOnlyNotes = hasSubscriptions && !profileOwnerUser.isSubscribed;
  const hideWatchButton = profileOwnerUser.isSubscribed;

  const showStats = isGroupProfile
    ? ['founded', 'members', 'watchers']
    : ['watchers', 'deviations', 'pageviews'];

  const rootDivRef = useRef<HTMLDivElement>(null);
  const { hero } = useHeroImageForContainer(coverDeviation, rootDivRef, {
    isViewportWidth: true,
    width: 1024,
    height: 160,
    mobileWidth: 412,
    mobileHeight: 160,
  });
  const openEditor = useCallback(
    e => {
      canChangeAvatar && openAvatarEditor(true);
      e.preventDefault();
    },
    [canChangeAvatar, openAvatarEditor]
  );

  return (
    <div
      className={classnames(
        s['root'],
        className,
        isGroupProfilesEnabled && isGroupProfile && s['group-profile']
      )}
    >
      <div className={s['hero-wrapper']} ref={rootDivRef}>
        {coverDeviation ? (
          <div
            className={classnames(s['background'], s['hero'])}
            style={{
              backgroundImage: hero ? `url(${hero.src})` : undefined,
            }}
          >
            {hero && (
              /* lets the preloader scan it */
              <img
                src={hero.src}
                alt=""
                width="0"
                height="0"
                style={{ display: 'none' }}
              />
            )}
          </div>
        ) : (
          <div className={classnames(s['background'], s['no-image'])} />
        )}
        <MonetizationPromoBanner className={s['tier-banner']} />
        {!isGroup && !isOwner && (
          <MoreActionsDropdownMobile className={s['more-menu']} />
        )}
      </div>

      {isAuthorizedToCustomize && isGroupProfilesEnabled && isGroupProfile && (
        <SkinButton className={s['skin-button']} />
      )}
      <div className={s['avatar']}>
        {profileOwnerUser.isBirthweek && <AvatarBirthdayBadge />}
        <div className={s['avatar-image']} onClick={openEditor}>
          <UserAvatar
            user={profileOwnerUser}
            size={profileOwnerUser.isGroup ? 50 : 100}
          />
        </div>
        {canChangeAvatar && <AvatarEdit />}
      </div>
      <div className={s['hug']}>
        <div className={s['info-text']}>
          <div className={s['username-skin-button-wrapper']}>
            <UserLink className={s['username']} user={profileOwnerUser} />
            {isAuthorizedToCustomize &&
              !(isGroupProfilesEnabled && isGroupProfile) && (
                <SkinButton className={s['skin-button']} />
              )}
          </div>
          <div className={s['tagline']}>{tagline}</div>
        </div>
        {!isGroup && !isOwner && (
          <div className={s['action-bar']}>
            {
              <NotesButton
                user={profileOwnerUser}
                className={classnames(
                  s['cta-button'],
                  showIconOnlyNotes && s['cta-button-icon-only']
                )}
                iconOnly={showIconOnlyNotes}
              />
            }
            {!hideWatchButton && <WatchButton user={profileOwnerUser} />}
            {showSubscribeButton && (
              <ProfileOwnerSubscribeButton
                user={profileOwnerUser}
                className={s['cta-button']}
              />
            )}
          </div>
        )}
        {isGroup && (
          <div className={s['group-action-bar']}>
            <JoinGroupButton
              type="solid"
              group={profileOwnerUser}
              className={s['cta-button']}
            />
            <SubmitButton className={s['cta-button']} />
            <MoreActionsDropdownMobile />
          </div>
        )}
        <div className={s['stats']}>
          {showStats.map(stat => (
            <ProfileStatItem
              key={stat}
              stat={stat}
              value={stats[stat]}
              className={s['stat-item']}
              countClassName={s['stat-item-count']}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
ProfileHeroMobile.displayName = 'ProfileHeroMobile';

export default ProfileHeroMobile;
